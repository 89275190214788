/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';

import { TransferModal } from '@swyftx/aviary/complex/TransferModal/TransferModal';
import { TransferEnum } from '@swyftx/aviary/complex/TransferModal/TransferModal.types';

import { Modals } from '@global-components/Modals/Modals.enum';

import { DashboardLayoutSelectorModal } from 'src/lib/dashboard/components/Modals';
import { DustingModal } from 'src/lib/markets/components';
import { GlobalSearch } from 'src/lib/search/GlobalSearch';
import { SwitchAccountModal } from 'src/lib/user-profile/components/SwitchAccountModal/SwitchAccountModal';

import { AboutCategoryModal } from './AboutCategoryModal';
import { AccountRestrictedModal } from './AccountRestrictedModal';
import { AffiliateSignUpModal } from './Affiliates';
import { ChangePasswordModal } from './ChangePasswordModal';
import { DemoModeLearnMoreModal } from './DemoModeLearnMoreModal/DemoModeLearnMoreModal';
import { DepositAddressChangesModal } from './DepositAddressChangesModal';
import { DisableTwoFactorWizard } from './DisableTwoFactorWizard';
import { EditTriggerOrdersModal } from './EditTriggerOrders';
import { EditTriggerOrdersAnnouncementModal } from './EditTriggerOrdersAnnouncementModal';
import { EnableTwoFactorWizard } from './EnableTwoFactorWizard';
import { HighSlippageReviewModal } from './HighSlippageReviewModal/HighSlippageReviewModal';
import { MultiTradeLimitationModal } from './MultiTradeLimitationModal';
import { NextDustTimeModal } from './NextDustTimeModal';
import { OTCTradesPermissionModal } from './OTCTradesPermissionModal/OTCTradesPermissionModal';
import { OrderDetailsModal } from './OrderDetailsModal';
import { PriceAlerts } from './PriceAlerts';
import { ProfitStopLossOrderModal, ProfitStopLossSuccessModal } from './ProfitStopLoss';
import { RedeemSwyftxEntryModal, RedeemSwyftxRewardModal } from './Rewards/RedeemSwyftxRewardModal';
import { SMSRecoveryModal } from './SMSRecoveryModal';
import { StripeCardDepositModal } from './StripeCardDepositModal';
import { TransactionDetailsModal } from './TransactionDetailsModal';
import { UpdateEmailAddressWizard } from './UpdateEmailAddressWizard';
import { UpdatePhoneWizard } from './UpdatePhoneWizard/UpdatePhoneWizard';
import { VerifyPhoneModal } from './VerifyPhoneModal';
import { WhatsNewModal } from './WhatsNewModal';

export const useModalMap = (props: any): { [key in Modals]: React.ReactElement } => {
  const modalMap = useMemo(
    () => ({
      [Modals.ChangePassword]: <ChangePasswordModal {...props} />,
      [Modals.EditTriggerOrders]: <EditTriggerOrdersModal {...props} />,
      [Modals.DemoModeLearnMore]: <DemoModeLearnMoreModal {...props} />,
      [Modals.DisableTwoFactor]: <DisableTwoFactorWizard {...props} />,
      [Modals.EditTriggerOrderAnnouncement]: <EditTriggerOrdersAnnouncementModal {...props} />,
      [Modals.EnableTwoFactor]: <EnableTwoFactorWizard {...props} />,
      [Modals.SMSRecovery]: <SMSRecoveryModal {...props} />,
      [Modals.UpdateEmailAddress]: <UpdateEmailAddressWizard {...props} />,
      [Modals.UpdatePhone]: <UpdatePhoneWizard {...props} />,
      [Modals.VerifyPhone]: <VerifyPhoneModal {...props} />,
      [Modals.WhatsNewModal]: <WhatsNewModal {...props} />,
      [Modals.RestrictedModal]: <AccountRestrictedModal {...props} />,
      [Modals.OrderDetails]: <OrderDetailsModal {...props} />,
      [Modals.OTCTradesPermissionModal]: <OTCTradesPermissionModal {...props} />,
      [Modals.TransactionDetails]: <TransactionDetailsModal {...props} />,
      [Modals.DepositReceive]: <TransferModal {...props} initialTransferType={TransferEnum.DepositReceive} open />,
      [Modals.WithdrawSend]: <TransferModal {...props} initialTransferType={TransferEnum.WithdrawSend} open />,
      [Modals.PriceAlerts]: <PriceAlerts {...props} open />,
      [Modals.StripeCardDeposit]: <StripeCardDepositModal {...props} />,
      [Modals.AffiliateSignUp]: <AffiliateSignUpModal {...props} />,
      [Modals.DustingModal]: <DustingModal {...props} defaultOpen />,
      [Modals.NextDustTimeModal]: <NextDustTimeModal {...props} />,
      [Modals.HighSlippageReviewModal]: <HighSlippageReviewModal {...props} />,
      [Modals.ProfitStopLossModal]: <ProfitStopLossOrderModal {...props} />,
      [Modals.ProfitStopLossSuccessModal]: <ProfitStopLossSuccessModal {...props} />,
      [Modals.AboutCategory]: <AboutCategoryModal {...props} />,
      [Modals.MultiTradeLimitation]: <MultiTradeLimitationModal {...props} />,
      [Modals.DepositAddressChangesModal]: <DepositAddressChangesModal {...props} />,

      /* Rewards */
      [Modals.RedeemSwyftxReward]: <RedeemSwyftxRewardModal {...props} />,
      [Modals.RedeemSwyftxEntry]: <RedeemSwyftxEntryModal {...props} />,

      [Modals.SwitchAccount]: <SwitchAccountModal {...props} defaultOpen />,

      [Modals.DashboardLayoutSelector]: <DashboardLayoutSelectorModal {...props} show />,

      [Modals.GlobalSearch]: <GlobalSearch {...props} defaultOpen />,
    }),
    [props],
  );

  return modalMap;
};
