import { useCallback, useMemo } from 'react';

import { AssetType } from '@shared/api';
import { AccountStatusEnum, GreenIdStatusEnum } from '@shared/enums';
import { UserStore } from '@shared/store';

import { EntityPermissions, UserPermissions } from '@hooks/Permissions';
import { PermissionType } from '@hooks/Permissions/permissions.types';

const useAccountFeatures = () => {
  const { scopeArray, isEntity, getEntityMember, getAccountStatus, canTransferCrypto, userProfile, isRestricted } =
    UserStore.useUserStore;

  const scopesToCheck = useMemo(
    () => (isEntity() ? getEntityMember()?.scope : scopeArray),
    [getEntityMember, isEntity, scopeArray],
  );

  const hasPermission = useCallback(
    (permission: PermissionType) => scopesToCheck?.includes(permission),
    [scopesToCheck],
  );

  const isAtLeastSilver = useMemo(() => AccountStatusEnum.SILVER <= getAccountStatus(), [getAccountStatus]);

  const hasValidVerification = useCallback(
    (assetType: AssetType) => {
      const validEntityVerification = isEntity() ? getEntityMember()?.verified === GreenIdStatusEnum.VERIFIED : true;
      if (!validEntityVerification) return false;

      const validAccountVerification = assetType === AssetType.Fiat ? isAtLeastSilver : canTransferCrypto();
      if (!validAccountVerification) return false;

      return true;
    },
    [canTransferCrypto, getEntityMember, isAtLeastSilver, isEntity],
  );

  const canDeposit = useCallback(
    (assetType: AssetType) => {
      if (!hasValidVerification(assetType)) return false;
      return hasPermission(EntityPermissions.ADDRESS_READ) || Boolean(userProfile?.accountStatus?.deposits);
    },
    [hasPermission, hasValidVerification, userProfile?.accountStatus?.deposits],
  );

  const canWithdraw = useCallback(
    (assetType: AssetType) => {
      if (!hasValidVerification(assetType)) return false;
      return hasPermission(UserPermissions.WITHDRAW_FUNDS) || Boolean(userProfile?.accountStatus?.withdrawals);
    },
    [hasPermission, hasValidVerification, userProfile?.accountStatus?.withdrawals],
  );

  return {
    canDeposit,
    canWithdraw,
    isRestricted,
  };
};

export { useAccountFeatures };
