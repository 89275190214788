import { useTranslation } from 'react-i18next';

import { AssetType } from '@shared/api';
import { AppStore } from '@shared/store';

import { useAccountFeatures } from '@hooks/Account';

import { TransferEnum } from '../../TransferModal.types';

type Error = {
  title: string;
  subTitle: string;
  errorType: 'standard' | 'verification' | 'restricted' | 'limit';
};

type Output = Error | null;

export const useTransferModalErrors = (type: TransferEnum, assetType: AssetType = AssetType.Fiat): Output => {
  const { t } = useTranslation('common', { keyPrefix: 'transferModal.errors' });
  const { isDemo } = AppStore.useAppStore;
  const { canDeposit, canWithdraw, isRestricted } = useAccountFeatures();

  if (isDemo) {
    return {
      title: t('demoModeTitle'),
      subTitle: type === TransferEnum.WithdrawSend ? t('withdrawDemoModeSubTitle') : t('depositDemoModeSubtitle'),
      errorType: 'standard',
    };
  }

  if (type === TransferEnum.DepositReceive && isRestricted()) {
    return {
      title: t('restrictedDepositTitle'),
      subTitle: t('restrictedDepositSubTitle'),
      errorType: 'limit',
    };
  }

  if (type === TransferEnum.WithdrawSend && isRestricted()) {
    return {
      title: t('restrictedWithdrawTitle'),
      subTitle: t('restrictedWithdrawSubTitle'),
      errorType: 'limit',
    };
  }

  if (type === TransferEnum.DepositReceive && !canDeposit(assetType)) {
    return {
      title: t('verificationDepositTitle'),
      subTitle: t('verificationDepositSubTitle'),
      errorType: 'verification',
    };
  }

  if (type === TransferEnum.WithdrawSend && !canWithdraw(assetType)) {
    return {
      title: t('verificationWithdrawTitle'),
      subTitle: t('verificationWithdrawSubTitle'),
      errorType: 'verification',
    };
  }

  return null;
};
